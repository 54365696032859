import React from "react";

export default function About() {
  return (
    <section id="about">
      <div className="container mx-auto flex px-10 py-20 md:flex-row flex-col items-center">
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">
            Hi, I'm Daniel
          </h1>
          <h2 className="title-font sm:text-3xl text-3xl mb-4 font-small text-white">
           I'm a Full Stack Developer, who loves building creative and intuitive apps
          </h2>          
          <p className="mb-8 leading-relaxed">
          Solution-minded Software Developer bringing 6+ years of experience. 
          Excellent reputation for solving difficult bugs, implementing high-quality features and dealing with escalations by promptly identifying defects and resolving swiftly. 
          Expert at complex query optimisation and problem solving.
          </p>

          <div className="container mx-auto md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <div>
            <div class="flex items-center flex-row">
              <img src="..\icon-uk.png" alt="UK"/>
              <h1 className="title-font sm:text-1xl text-1xl font-small text-white">&nbsp;Based in the UK</h1>
            </div>
          </div>

          <div>
            <div class="flex items-center flex-row">
              <img src="..\icon-coffee.png" alt="Coffee"/>
              <h1 className="title-font sm:text-1xl text-1xl font-small text-white">&nbsp;Fueled by Coffee</h1>
            </div>
          </div>

          <div>
            <div class="flex items-center flex-row">
              <img src="..\icon-briefcase.png" alt="Brief Case"/>
              <h1 className="title-font sm:text-1xl text-1xl font-small text-white">&nbsp;Software Developer at iSAMS</h1>
            </div>
          </div>

          <div>
            <div class="flex items-center flex-row">
              <img src="..\icon-email.png" alt="Email"/>
              <h1 className="title-font sm:text-1xl text-1xl font-small text-white">&nbsp;danielgillard98@gmail.com</h1>
            </div>
          </div>
          </div>
          </div>

          <br></br>          

          <div className="flex justify-center">
            <a
              href="#contact"
              className="inline-flex text-white bg-green-500 border-0 py-2 px-6 focus:outline-none hover:bg-green-600 rounded text-lg">
              Contact Me
            </a>
            <a
              href="#projects"
              className="ml-4 inline-flex text-gray-400 bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-700 hover:text-white rounded text-lg">
              My Projects
            </a>
            <a
              href="..\Daniel Gillard - CV.docx"
              className="ml-4 inline-flex text-gray-400 bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-700 hover:text-white rounded text-lg">
              Curriculum Vitae
            </a>
          </div>
        </div>
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
          <img
            className="object-cover object-center rounded"
            alt="hero"
            src="./me-circle.png"
          />
        </div>
      </div>    
    </section>
  );
}
