export const projects = [
  {
    title: "OCR Screenshot Tool",
    subtitle: ".NET WinForms",
    description:
      "A lightweight screen-capture tool with built in OCR using tesseract libraries",
    image: "./project-1.gif",
    link: "",
  },
  {
    title: "Personal Wedding site wwith RSVP",
    subtitle: "HTML & CSS",
    description:
      "A website I created to send to wedding guests, allowing them to RSVP to our celebrations and donate to our wedding fund",
    image: "./project-2.gif",
    link: "",
  },
  {
    title: "3D Horror/Adventure game",
    subtitle: "C#, Unity & Blender 3D",
    description:
      "A short horror themed game built in Unity. Outside of work I enjoy using Blender to create 3D assets",
    image: "./project-3.gif",
    link: "",
  }
];

export const testimonials = [
  {
    quote:
      "Dan joined Technical Support in 2017 and has grown over time to a highly valuged member of staff, both within his own team and elsewhere in the business. He has been seconded to the Installs department, Development and has created systems that will benefit iSAMS for years to come. The Development team have taken on an absolute star.",
    image: "https://randomuser.me/api/portraits/men/1.jpg",
    name: "Dave Tonks",
    company: "iSAMS",
  },
  {
    quote:
      "Dan is genuinely motivated and is interested in everything Technical. He takes all tasks seriously and always delivers despite the challenges they may prove.",
    image: "https://randomuser.me/api/portraits/men/94.jpg",
    name: "Imran Kazi",
    company: "iSAMS",
  },
];

export const career = [
  {
    
  }
];

export const skills = [
  "JavaScript",
  "React",
  "Node",
  "SQL",
  "C#",
  ".NET Framework",
  "ASP",
  "Git",
  "Powershell",
  "Azure"
];
