import React from "react";
import { BriefcaseIcon } from "@heroicons/react/solid";

export default function Career() {    
    return (
        <section id="career" className="text-gray-400 bg-gray-900 body-font">
            <div className="container px-5 py-10 mx-auto text-center lg:px-40">
                <div className="flex flex-col w-full mb-20">
                    <BriefcaseIcon className="mx-auto inline-block w-10 mb-4" />
                        <h1 className="sm:text-4xl text-3xl font-medium title-font mb-4 text-white">
                        Work Experience
                        </h1>
                        <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
                        A brief history of my Career
                        </p>                                                                       
                </div>                    
                <ol class="border-l border-gray-300">                  
                  <li>
                    <div class="flex flex-start items-center pt-3">
                      <div class="bg-gray-300 w-2 h-2 rounded-full -ml-1 mr-3"></div>
                      <p class="text-gray-500 text-sm">14th February 2022</p>      
                    </div>
                    <img src="..\iSAMS_100x100.png" alt="iSAMS" className="absolute inset-x-40 hidden md:block"/>
                    <div class="mt-0.5 ml-4 mb-6">
                      <h4 class="text-white font-semibold text-xl mb-1.5 hover:text-gray-200"><a href="https://www.isams.com/">iSAMS</a></h4>
                      <h5 class="text-white text-l mb-1.5">Software Developer</h5>
                      <p class="text-white-500 mb-3">As a Software Engineer at iSAMS we work off bi-weekly sprints where we are assigned tickets via Azure DevOps from Business Analysts. We generally have an agile working pattern to quickly and efficiently produce results for clients. We use GIT on a day-to-day basis to commit pull requests and merge into our master branch.</p>
                      <p class="text-white-500 mb-3">Our Tech Stack revolves around the .NET framework, working with languages such as C#, ASP.Net MVC, JavaScript & TypeScript, whilst running off a SQL Server database using Visual Studio.</p>
                      <p class="text-white-500 mb-3">I am also a member of the Integrations team, where I have developed multiple Azure based function apps, using a fan-in fan-out approach, integrating into a secure keyvault and cosmos db for non-secure data. I also have experience in bicep configuration and build pipelines.</p>
                      <p class="text-white-500 mb-3">I have also written a bespoke winforms application which handles Excel/csv data validation and bulk imports into our database using JSON configuration files.</p>
                      <p class="text-white-500 mb-3">I ensure that my code is well formatted, and has suitable unit tests using the Moq and Xunit frameworks to improve readability and reliability.</p>
                    </div>
                  </li>
                  <li>
                    <div class="flex flex-start items-center pt-2">
                      <div class="bg-gray-300 w-2 h-2 rounded-full -ml-1 mr-3"></div>
                      <p class="text-gray-500 text-sm">28th May 2017</p>
                    </div>
                    <div class="mt-0.5 ml-4 mb-6">    
                      <h5 class="text-white text-l mb-1.5">Technical Support Analyst</h5>
                      <p class="text-white-500 mb-3">Throughout my career at iSAMS I have been able to efficiently resolve incidents and work through complex SQL tasks. During my time in Technical Support I have resolved over 6000 complex tickets whilst under pressure to meet strict SLA deadlines. I have extensive experience in debugging sophisticated stored procedures, creating custom databases and optimising existing T-SQL to improve efficiency. I have also been relied upon in multiple different departments.
                        <br></br>
                        <br></br>
                        <b>Integrations</b>
                        <br></br>
                        Whilst in the Integrations team, I was tasked with writing business critical API based integrations to synchronise sensitive data between iSAMS and partner applications. These integrations were written in JavaScript and used XML and JSON. I am experienced with testing software such as Postman to ensure that all services are functioning correctly.
                        <br></br>
                        <br></br>
                        <b>Implementations</b>
                        <br></br>
                        I was temporarily seconded into the implementations team twice during my time at iSAMS which involved creating databases from scratch and meeting time crucial deadlines. I was also required to create complex SSRS reports for clients to meet their requirements.
                        <br></br>
                        <br></br>
                        <b>Development</b>
                        <br></br>
                        During my time in the development team, I assisted on multiple projects, creating Azure Web Functions from scratch and also writing dynamic automation software for data imports and database management.
                      </p>
                    </div>
                  </li>
              </ol>

              <div class="relative flex py-5 items-center w-80 mx-auto text-center">
                  <div class="flex-grow border-t border-gray-400"></div>    
                  <div class="flex-grow border-t border-gray-400"></div>
              </div>

              <br></br>

              <ol class="border-l border-gray-300">
                <li>
                  <div class="flex flex-start items-center pt-3">
                    <div class="bg-gray-300 w-2 h-2 rounded-full -ml-1 mr-3"></div>
                    <p class="text-gray-500 text-sm">1st May 2016</p>
                  </div>
                  <img src="..\Magma_100x59.jpg" alt="Magma Trading" className="absolute inset-x-40 hidden md:block"/>
                  <div class="mt-0.5 ml-4 mb-6">
                    <h4 class="text-white font-semibold text-xl mb-1.5 hover:text-gray-200">Magma Trading</h4>
                    <h5 class="text-white text-l mb-1.5">Apprentice Software Engineer</h5>
                    <p class="text-white-500 mb-3">Coming out of my college education I decided to opt for an Apprenticeship in Software development. Magma trading developed WMS (Warehouse Management Systems) and I was tasked with supporting existing software.
                    <br></br>
              <br></br>
              During my time at Magma I wrote stored procedures and complex T-SQL to query large scale databases. I also wrote an application designed for hand-held barcode scanners to read stock from the warehouse which would report back to the database and display on office workers WMS.
              <br></br>
              <br></br>
              I worked on creating automated SSRS reports that would feedback crucial data on monthly stock levels, SKU numbers and employee data.
              </p>
                  </div>
                </li>
              </ol>

            </div>
        </section>
    );
}
